import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'modules/Layout';
import Account from 'modules/Account';
import messages from '../../modules/Layout/messages';


function action({ route },) {

  return {
    chunks   : ['account'],
    name     : route.name,
    component: (
      <Layout.components.DashboardLayout>
        <header className="text--h1 mb-6">
          <FormattedMessage {...messages.headers.dashboard} />
        </header>
        <Account.components.WelcomeStatement />
      </Layout.components.DashboardLayout>
    ),
  };
}

export default action;
